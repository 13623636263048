<template>
    <base-page>
        <titlu-pagina Titlu="Hotel anvelope" :AdaugaVisible='false'></titlu-pagina>

        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix" style="text-align:center" >
                <strong> Filtre </strong>                
            </div>
            <div class="filtre" style="text-align:center; ">
                <el-form :inline="true" @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-form-item label='Masina'>                           
                            <SelectMasina 
                                class="full-width input-group-input" 
                                v-model="numarMasina" 
                                :NumarMasina="numarMasina" 
                                :IdMasina.sync="Filters.IdMasina" 
                                @selectie="select_masina" />
                        </el-form-item>
                        <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        <el-button @click='reset'> Reset </el-button>
                        <el-button style="margin-left:30px" type="warning" @click='exportExcel'> Export Excel </el-button>
                       
                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-table :data="Rezultate" v-loading="loading" @expand-change="expand" >

            <el-table-column type="expand" >       
                <template slot-scope="props">                     
                    <HotelAnvelopeCard ref="HotelAnvelopeCard" :Masina="props.row"/>
                </template>
            </el-table-column>

            <el-table-column prop='Numar' label='Masina' width="200px">
                <template slot-scope="scope">
                    <span style="font-size:30px">{{scope.row.Numar}}</span>                    
                </template>   
            </el-table-column>             
            
            <el-table-column prop='Info' width="450px" >
                <template slot-scope="scope">
                    <span style="font-size:20px; color: #8492a6;">{{scope.row.Info}}</span> 
                </template>
            </el-table-column>

            <el-table-column prop="Marca" label="Marca">
                <template slot-scope="scope">
                    <span style="font-size:20px;"> {{scope.row.Marca}} </span>
                </template>
            </el-table-column>
            <el-table-column prop="Locatie" label="Locatie">
                <template slot-scope="scope">
                    <div style="font-size:20px;" v-if="scope.row.Locatie == '' "> - </div>
                    <div style="font-size:20px;" v-else> {{scope.row.Locatie}} </div>                         
                </template>
            </el-table-column>
            <el-table-column prop="Anvelope" label="Anvelope">
                <template slot-scope="scope">
                    <div style="font-size:20px;" v-if="scope.row.Anvelope.length > 0 "> {{scope.row.Anvelope[0].Cantitate}} </div>
                    <div style="font-size:20px;" v-else> 0 </div>                         
                </template>
            </el-table-column>
            <el-table-column prop="Jante" label="Jante">
                <template slot-scope="scope">
                    <div style="font-size:20px;" v-if="scope.row.Jante.length > 0 "> {{scope.row.Jante[0].Cantitate}} </div>
                    <div style="font-size:20px;" v-else> 0 </div>                         
                </template>
            </el-table-column>
           
        </el-table>
       
       <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
    </base-page>

</template>

<script>
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import TitluPagina from '../widgets/TitluPagina';
import SelectMasina from '@/components/SelectMasina'
import HotelAnvelopeCard from '@/components/hotel_anvelope/HotelAnvelopeCard.vue'

export default {
    name: "hotel_anvelope",
    extends: BasePage,
    components: {
        'base-page'   : BasePage,
        'titlu-pagina': TitluPagina,
        HotelAnvelopeCard,
        SelectMasina
    },
    data () {
        return {
            Results: [],
            Rezultate: [],
            base_url: '',
            Info: {masini: []},                
            Filters: { IdMasina: '-1'},                
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            loading: true,
            numarMasina:'',
            base_url:''
        }
    }, 
    methods: {
        async get_info(){                  
            this.refresh_info();
        },

        async refresh_info(){            
            var response        = await this.post("hotel_anvelope/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Rezultate      = response.Rezultate;
            this.PaginationInfo = response.PaginationInfo;                        
            this.loading        = false;
        },
        select_masina(item){
            this.numarMasina = item.Numar
        },
        reset(){
            this.Filters = { IdMasina: '-1' };   
            this.numarMasina = ''             
            this.refresh_info();
        },  
        async expand(row)  {
            // await this.post("CRM/update_masina", {id_masina: row.Id})            
        },
        exportExcel(){            
           window.open( `${this.base_url}/hotel_anvelope/export_excel/`);        
        },
        export_excel(){
            var token    = settings.get_token();
            var filters  = encodeURI( JSON.stringify( this.Filters          ) );
            var order_by = encodeURI( JSON.stringify( this.OrderBy          ) );
            var pg_info  = encodeURI( JSON.stringify( this.PaginationInfo   ) ); 
        }
    },
    mounted(){
        this.get_info();
        this.base_url = settings.BASE_URL;
    }
};
</script>

<style lang="less" scoped>

.top50{
    margin-top: 20px;
}

</style>
